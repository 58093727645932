import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Star } from 'lucide-react';
import api from '../api';

interface GoalUpdateDetails {
    goal: {
        description: string;
        company: {
            name: string;
        };
    };
    content: string;
    owner_name?: string;
    reviewer_email?: string;
    review_status: {
        status: 'pending' | 'completed';
        completed_at?: string;
        rating?: number;
        comment?: string;
    };
}

interface FeedbackFormProps {
    onSubmitSuccess?: () => void;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onSubmitSuccess }) => {
    const [rating, setRating] = useState<number>(5);
    const [comment, setComment] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [details, setDetails] = useState<GoalUpdateDetails | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGoalDetails = async () => {
            try {
                const response = await api.get(`/api/goals/updates/review/${token}/`);
                setDetails(response.data);
            } catch (err) {
                setError('Failed to load goal details. The link might be invalid or expired.');
            } finally {
                setIsLoading(false);
            }
        };

        if (token) {
            fetchGoalDetails();
        }
    }, [token]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');

        try {
            const searchParams = new URLSearchParams(window.location.search);
            const reviewerEmail = searchParams.get('email');

            await api.post(`/api/goals/updates/review/${token}/`, {
                rating,
                comment,
                reviewer_email: reviewerEmail
            });

            if (onSubmitSuccess) {
                onSubmitSuccess();
            }
            
            navigate('/feedback/success');
        } catch (err) {
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md text-center">
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
                <div className="text-red-600 mb-4">{error}</div>
                <button
                    onClick={() => navigate('/')}
                    className="text-blue-600 hover:underline"
                >
                    Return to Home
                </button>
            </div>
        );
    }

    if (!details) {
        return (
            <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md text-center">
                <p>No goal details found.</p>
            </div>
        );
    }

    if (details?.review_status.status === 'completed') {
        return (
            <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Feedback Already Submitted</h2>
                    <div className="bg-gray-50 p-4 rounded-lg mb-6">
                        <p className="text-gray-600 mb-2">Your feedback was submitted on:</p>
                        <p className="font-medium">
                            {new Date(details.review_status.completed_at!).toLocaleString()}
                        </p>
                    </div>
                    
                    <div className="bg-gray-50 p-4 rounded-lg mb-6">
                        <p className="text-gray-600 mb-2">Your Rating:</p>
                        <div className="flex gap-1">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <Star
                                    key={value}
                                    fill={value <= (details.review_status.rating || 0) ? 'gold' : 'none'}
                                    className="w-6 h-6"
                                />
                            ))}
                        </div>
                    </div>

                    {details.review_status.comment && (
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <p className="text-gray-600 mb-2">Your Comment:</p>
                            <p className="font-medium italic">"{details.review_status.comment}"</p>
                        </div>
                    )}
                </div>

                <button
                    onClick={() => navigate('/')}
                    className="w-full bg-black text-white py-3 px-4 rounded-lg hover:bg-black/90 transition-colors"
                >
                    Return to Home
                </button>
            </div>
        );
    }

    return (
        <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Goal Review Request</h2>
                <div className="bg-gray-50 p-4 rounded-lg mb-6">
                    <p className="text-gray-600 mb-2">Goal:</p>
                    <p className="font-medium">{details.goal.description}</p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-gray-600 mb-2">Update Content:</p>
                    <p className="font-medium">{details.content}</p>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium mb-4">
                        Rating:
                    </label>
                    <div className="flex gap-2">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <button
                                key={value}
                                type="button"
                                onClick={() => setRating(value)}
                                className={`p-2 rounded-full transition-all ${
                                    rating >= value
                                        ? 'text-yellow-400 hover:text-yellow-500'
                                        : 'text-gray-300 hover:text-gray-400'
                                }`}
                            >
                                <Star
                                    fill={rating >= value ? 'currentColor' : 'none'}
                                    className="w-8 h-8"
                                />
                            </button>
                        ))}
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                        {rating === 1 ? "Poor" :
                         rating === 2 ? "Fair" :
                         rating === 3 ? "Good" :
                         rating === 4 ? "Very Good" :
                         "Excellent"}
                    </p>
                </div>

                <div>
                    <label className="block text-sm font-medium mb-2">
                        Comments:
                    </label>
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 h-32"
                        placeholder="Please provide your feedback..."
                        required
                    />
                </div>

                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-black text-white py-3 px-4 rounded-lg hover:bg-black/90 disabled:opacity-50 transition-colors"
                >
                    {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                </button>
            </form>
        </div>
    );
};

export default FeedbackForm; 