'use client'

import React, { useState, useEffect, useMemo } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { 
  ChevronDown, 
  ChevronRight, 
  User, 
  Briefcase, 
  MapPin, 
  Calendar, 
  Users,
  Brain,
  ClipboardList,
  LineChart,
  FileText,
  Target
} from 'lucide-react'

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import api from '../api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "./ui/dialog"
import { Button } from "./ui/button"
import { Checkbox } from "./ui/checkbox"

// Update types to match the new schema
type KPI = {
  id: number;
  description: string;
  needs_review: boolean;
}

type KRA = {
  id: number;
  description: string;
  needs_review: boolean;
  responsibilities: Array<string | { description: string }>;
}

type Decision = {
  id: number;
  description: string;
  status: string;
  decision_priority: string;
  deadline: string;
  decision_level: string;
  inform: Array<{ employee_id: string; name: string }>;
  advise: Array<{ employee_id: string; name: string }>;
  decide: Array<{ employee_id: string; name: string }>;
  execute: Array<{ employee_id: string; name: string }>;
}

type Role = {
  id: number;
  title: string;
  department: string;
  sub_department_level_1?: string;
  sub_department_level_2?: string;
  cost_center?: string;
  location: string;
  grade: string;
  reporting_to: {
    id: number;
    title: string;
    current_employee: {
      id: number;
      name: string;
      employee_id: string;
    } | null;
  } | null;
  kras: KRA[];
  kpis: KPI[];
  goals: Goal[];
}

type Employee = {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string | null;
  is_active: boolean;
  competencies: Array<string | { description: string }>;
}

type EmployeeData = {
  employee: Employee;
  role: Role;
  decisions: Decision[];
  has_acknowledged_kras: boolean;
}

type GoalUpdate = {
  id: number;
  type: "self" | "internal" | "external";
  content: string;
  progress: number;
  raters: string[];
  review_status: 'no_review' | 'pending' | 'awaiting_feedback' | 'completed';
  created_at: string;
  review_requests: ReviewRequest[];
};

type ReviewRequest = {
  reviewer_email: string;
  status: 'pending' | 'completed';
  rating?: number;
  comment?: string;
  created_at: string;
  completed_at?: string;
};

type Goal = {
  id: number;
  title: string;
  description: string;
  goal_type: 'personal' | 'company';
  progress: number;
  owner: string | null;
  owner_details: {
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  } | null;
  internal_reviewer_details: Array<{
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  }>;
  external_reviewers: string[];
  timeline: string;
  timeline_duration: string;
  timeline_year: number;
  company: string;
  created_at: string;
  updated_at: string;
  updates: GoalUpdate[];
  parent_goal?: number | null;
  parent_goal_details?: {
    id: number;
    title: string;
    progress: number;
  } | null;
};

const priorityColors: { [key: string]: string } = {
  'Critical': 'text-red-600 bg-red-50',
  'High': 'text-orange-600 bg-orange-50',
  'Medium': 'text-yellow-600 bg-yellow-50',
  'Low': 'text-green-600 bg-green-50'
}

const decisionLevelColors: { [key: string]: string } = {
  'Strategic': 'text-purple-600 bg-purple-50',
  'Tactical': 'text-indigo-600 bg-indigo-50',
  'Routine': 'text-blue-600 bg-blue-50'
}

const EmployeeDashboard: React.FC = () => {
  const { user, updateUser } = useAuth()
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null)
  const [expandedKRAs, setExpandedKRAs] = useState<boolean>(false)
  const [expandedKPIs, setExpandedKPIs] = useState<boolean>(false)
  const [expandedCompetencies, setExpandedCompetencies] = useState<boolean>(false)
  const [expandedGoals, setExpandedGoals] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [showAcknowledgmentModal, setShowAcknowledgmentModal] = useState(false)
  const [isAcknowledged, setIsAcknowledged] = useState(false)
  const [personalGoals, setPersonalGoals] = useState<Goal[]>([]);

  // Fetch employee data
  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (!user?.employeeId) {
        setError('Employee ID not found. Please log in again.')
        setLoading(false)
        return
      }

      try {
        const response = await api.get(`/api/employee-dashboard/${user.employeeId}/`)
        setEmployeeData(response.data)
      } catch (err) {
        console.error('Error fetching employee data:', err)
        setError('Failed to load employee data. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    fetchEmployeeData()
  }, [user])

  // Show acknowledgment modal if needed
  useEffect(() => {
    if (user && user.role === 'employee' && !user.hasAcknowledgedKras) {
      setShowAcknowledgmentModal(true);
    } else {
      setShowAcknowledgmentModal(false);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hasAcknowledgedKras]);

  // Fetch personal goals
  useEffect(() => {
    const fetchPersonalGoals = async () => {
      if (!user?.employeeId || !user?.company) return;

      try {
        const response = await api.get(`/api/companies/${user.company}/goals/`, {
          params: {
            type: 'personal',
            employee_id: user.employeeId,
            include_updates: true
          }
        });
        setPersonalGoals(response.data);
      } catch (error) {
        console.error('Error fetching personal goals:', error);
      }
    };

    fetchPersonalGoals();
  }, [user?.employeeId, user?.company]);

  // Move useMemo to the top level, unconditionally
  const combinedGoals = useMemo(() => {
    return [...(employeeData?.role.goals || []), ...personalGoals];
  }, [employeeData?.role.goals, personalGoals]);

  const handleAcknowledgment = async () => {
    try {
      const response = await api.post('/api/accounts/acknowledge-kras/', {
        userId: user?.userId
      });
      
      if (response.data.status === 'success') {
        setShowAcknowledgmentModal(false);
        if (user) {
          const updatedUser = { ...user, hasAcknowledgedKras: true };
          updateUser(updatedUser);
        }
      }
    } catch (error) {
      console.error('Error acknowledging KRAs:', error);
    }
  };

  const toggleSection = (section: 'kras' | 'kpis' | 'competencies' | 'goals') => {
    switch (section) {
      case 'kras':
        setExpandedKRAs(!expandedKRAs);
        break;
      case 'kpis':
        setExpandedKPIs(!expandedKPIs);
        break;
      case 'competencies':
        setExpandedCompetencies(!expandedCompetencies);
        break;
      case 'goals':
        setExpandedGoals(!expandedGoals);
        break;
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-600">{error}</div>
  }

  if (!employeeData) {
    return <div className="flex justify-center items-center h-screen">No employee data available.</div>
  }

  const { employee, role, decisions } = employeeData

  const categorizedDecisions = {
    I: decisions.filter(d => d.inform?.some(e => e.employee_id === employee.employee_id) ?? false),
    A: decisions.filter(d => d.advise?.some(e => e.employee_id === employee.employee_id) ?? false),
    D: decisions.filter(d => d.decide?.some(e => e.employee_id === employee.employee_id) ?? false),
    E: decisions.filter(d => d.execute?.some(e => e.employee_id === employee.employee_id) ?? false),
  }

  // Use combinedGoals unconditionally in the rendering
  
  return (
    <div className="flex flex-col h-full w-full bg-cream text-gray-900">
      {/* Header */}
      <div className="sticky top-0 bg-gradient-to-r from-blue-50 to-indigo-50 z-20 px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-bold text-gray-800">{employee.name}</h1>
        <p className="text-gray-600">{role.title} - {role.department}</p>
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-auto px-6 py-4">
        {/* Employee Details Card */}
        <Card className="mb-6 shadow-md">
          <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
            <CardTitle>Employee Details</CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-2 gap-4 p-6">
            {employee.employee_id && (
              <div className="flex items-center">
                <User size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">ID:</span>
                <span className="ml-2">{employee.employee_id}</span>
              </div>
            )}
            
            {role.department && (
              <div className="flex items-center">
                <Briefcase size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Department:</span>
                <span className="ml-2">{role.department}</span>
              </div>
            )}
            
            {role.location && role.location.trim() !== '' && (
              <div className="flex items-center">
                <MapPin size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Location:</span>
                <span className="ml-2">{role.location}</span>
              </div>
            )}
            
            {role.grade && (
              <div className="flex items-center">
                <Users size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Grade:</span>
                <span className="ml-2">{role.grade}</span>
              </div>
            )}

            {role.sub_department_level_1 && role.sub_department_level_1.trim() !== '' && (
              <div className="flex items-center">
                <Users size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Sub Department 1:</span>
                <span className="ml-2">{role.sub_department_level_1}</span>
              </div>
            )}

            {role.sub_department_level_2 && role.sub_department_level_2.trim() !== '' && (
              <div className="flex items-center">
                <Users size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Sub Department 2:</span>
                <span className="ml-2">{role.sub_department_level_2}</span>
              </div>
            )}

            {role.cost_center && role.cost_center.trim() !== '' && (
              <div className="flex items-center">
                <Briefcase size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Cost Center:</span>
                <span className="ml-2">{role.cost_center}</span>
              </div>
            )}

            {employee.date_of_joining && (
              <div className="flex items-center col-span-2">
                <Calendar size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Joined:</span>
                <span className="ml-2">
                  {new Date(employee.date_of_joining).toLocaleDateString()}
                </span>
              </div>
            )}

            {role.reporting_to?.current_employee && (
              <div className="flex items-center col-span-2">
                <Users size={16} className="mr-2 text-muted-foreground" />
                <span className="text-muted-foreground font-medium">Reports to:</span>
                <span className="ml-2">{role.reporting_to.current_employee.name}</span>
              </div>
            )}
          </CardContent>
        </Card>

        <div className="grid grid-cols-2 gap-6">
          {/* KRAs Section */}
          <div className="h-fit">
            <Card className="shadow-md overflow-hidden">
              <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
                <div className="flex items-center justify-between">
                  <CardTitle className="flex items-center">
                    <ClipboardList size={20} className="mr-2" />
                    Key Result Areas (KRAs)
                  </CardTitle>
                  <button onClick={() => toggleSection('kras')}>
                    {expandedKRAs ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                  </button>
                </div>
              </CardHeader>
              <CardContent 
                className={`transition-all duration-200 overflow-hidden ${
                  expandedKRAs ? 'max-h-[1000px] opacity-100 p-6' : 'max-h-0 opacity-0 p-0'
                }`}
              >
                {role.kras?.length > 0 ? (
                  role.kras.map((kra) => (
                    <div key={kra.id} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                      <p className="font-medium">{kra.description}</p>
                      {Array.isArray(kra.responsibilities) && kra.responsibilities.length > 0 && (
                        <div className="mt-2 pl-4">
                          <p className="text-sm font-medium text-muted-foreground mb-1">Responsibilities:</p>
                          <ul className="list-disc list-inside space-y-1">
                            {kra.responsibilities.map((resp, index) => (
                              <li key={index} className="text-sm text-gray-600">
                                {typeof resp === 'string' ? resp : resp.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-muted-foreground">No KRAs available</p>
                )}
              </CardContent>
            </Card>
          </div>

          {/* KPIs Section */}
          <div className="h-fit">
            <Card className="shadow-md overflow-hidden">
              <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
                <div className="flex items-center justify-between">
                  <CardTitle className="flex items-center">
                    <LineChart size={20} className="mr-2" />
                    Key Performance Indicators (KPIs)
                  </CardTitle>
                  <button onClick={() => toggleSection('kpis')}>
                    {expandedKPIs ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                  </button>
                </div>
              </CardHeader>
              <CardContent 
                className={`transition-all duration-200 overflow-hidden ${
                  expandedKPIs ? 'max-h-[1000px] opacity-100 p-6' : 'max-h-0 opacity-0 p-0'
                }`}
              >
                {role.kpis?.length > 0 ? (
                  role.kpis.map((kpi) => (
                    <div key={kpi.id} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                      <p className="font-medium">{kpi.description}</p>
                    </div>
                  ))
                ) : (
                  <p className="text-muted-foreground">No KPIs available</p>
                )}
              </CardContent>
            </Card>
          </div>

          {/* Goals Section */}
          <Card className="mt-6 shadow-md overflow-hidden">
            <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
              <div className="flex items-center justify-between">
                <CardTitle className="flex items-center">
                  <Target size={20} className="mr-2" />
                  Goals
                </CardTitle>
                <button onClick={() => toggleSection('goals')}>
                  {expandedGoals ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                </button>
              </div>
            </CardHeader>
            <CardContent 
              className={`transition-all duration-200 overflow-hidden ${
                expandedGoals ? 'max-h-[1000px] opacity-100 p-6' : 'max-h-0 opacity-0 p-0'
              }`}
            >
              { combinedGoals.length > 0 ? (
                <div className="space-y-4">
                  {combinedGoals.map((goal) => (
                    <div key={goal.id} className="p-4 bg-muted rounded-lg space-y-2">
                      <h4 className="font-medium">
                        {goal.title || goal.description}
                      </h4>
                      <div className="space-y-1">
                        <div className="w-full bg-background rounded-full h-2 border border-border">
                          <div 
                            className="bg-primary h-full rounded-full transition-all min-w-[2px]"
                            style={{ width: `${goal.progress}%` }}
                          />
                        </div>
                        <div className="text-sm text-muted-foreground">
                          <span>{goal.progress}% complete</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted-foreground text-center py-4">No goals available</p>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Decisions Section */}
        <Card className="mt-6 shadow-md overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
            <CardTitle className="flex items-center">
              <FileText size={20} className="mr-2" />
              Decisions
            </CardTitle>
          </CardHeader>
          <CardContent>
            {Object.entries(categorizedDecisions).some(([_, decisions]) => decisions.length > 0) ? (
              Object.entries(categorizedDecisions)
                .filter(([_, decisions]) => decisions.length > 0)
                .map(([category, decisions]) => (
                  <div key={category} className="mb-6 last:mb-0">
                    <h3 className="text-lg font-semibold mb-2">{getCategoryFullName(category)}</h3>
                    <div className="space-y-4">
                      {decisions.map(decision => (
                        <div key={decision.id} className="p-3 bg-muted rounded-lg">
                          <p className="font-medium">{decision.description}</p>
                          <div className="flex flex-wrap gap-2 mt-2">
                            <span className={`text-xs px-2 py-1 rounded-full ${priorityColors[decision.decision_priority]}`}>
                              {decision.decision_priority}
                            </span>
                            <span className={`text-xs px-2 py-1 rounded-full ${decisionLevelColors[decision.decision_level]}`}>
                              {decision.decision_level}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
            ) : (
              <p className="text-muted-foreground text-center py-4">No decisions assigned yet</p>
            )}
          </CardContent>
        </Card>

        {/* Competencies Section */}
        <Card className="mt-6 shadow-md overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex items-center justify-between">
              <CardTitle className="flex items-center">
                <Brain size={20} className="mr-2" />
                Competencies
              </CardTitle>
              <button onClick={() => toggleSection('competencies')}>
                {expandedCompetencies ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              </button>
            </div>
          </CardHeader>
          <CardContent 
            className={`transition-all duration-200 overflow-hidden ${
              expandedCompetencies ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            {employee.competencies?.length > 0 ? (
              employee.competencies.map((competency, index) => (
                <div key={index} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                  <p className="font-medium">{typeof competency === 'string' ? competency : competency.description}</p>
                </div>
              ))
            ) : (
              <p className="text-muted-foreground">No competencies available</p>
            )}
          </CardContent>
        </Card>
      </div>

      {showAcknowledgmentModal && (
        <Dialog 
          open={showAcknowledgmentModal} 
          onOpenChange={() => {}} // Prevent closing the modal without acknowledgment
        >
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>Welcome to Your Role</DialogTitle>
              <DialogDescription>
                Please review your Key Result Areas (KRAs) and Key Performance Indicators (KPIs) carefully.
                You need to acknowledge these before proceeding.
              </DialogDescription>
            </DialogHeader>
            
            <div className="max-h-[60vh] overflow-y-auto">
              <div className="space-y-4">
                <h3 className="font-semibold">Key Result Areas (KRAs)</h3>
                {role.kras?.map((kra) => (
                  <div key={kra.id} className="p-3 bg-muted rounded-lg">
                    <p>{kra.description}</p>
                    {kra.responsibilities && (
                      <ul className="mt-2 ml-4 list-disc">
                        {kra.responsibilities.map((resp, idx) => (
                          <li key={idx} className="text-sm">
                            {typeof resp === 'string' ? resp : resp.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}

                <h3 className="font-semibold mt-6">Key Performance Indicators (KPIs)</h3>
                {role.kpis?.map((kpi) => (
                  <div key={kpi.id} className="p-3 bg-muted rounded-lg">
                    <p>{kpi.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center space-x-2 mt-4">
              <Checkbox
                id="acknowledge"
                checked={isAcknowledged}
                onCheckedChange={(checked) => setIsAcknowledged(checked as boolean)}
              />
              <label
                htmlFor="acknowledge"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I acknowledge that I have read and understood my KRAs and KPIs
              </label>
            </div>

            <DialogFooter>
              <Button
                onClick={handleAcknowledgment}
                disabled={!isAcknowledged}
              >
                Acknowledge and Continue
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

function getCategoryFullName(category: string): string {
  switch (category) {
    case 'I': return 'Inform'
    case 'A': return 'Advise'
    case 'D': return 'Decide'
    case 'E': return 'Execute'
    default: return category
  }
}

export default EmployeeDashboard

