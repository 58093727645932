import React from 'react';
import { EdgeProps, useStore } from 'reactflow';

const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  markerEnd,
  data,
  source,
}) => {
  // Constants for edge rendering
  const VERTICAL_MARGIN = 30;
  const EDGE_RADIUS = 20;
  const IC_OFFSET = 20;

  // Get all edges and nodes from the store
  const edges = useStore((state) => 
    state.edges.filter(e => 
      e.source === source && 
      e.data?.isVerticalIC === data?.isVerticalIC
    )
  );
  
  const getHierarchicalPath = () => {
    const isIC = data?.isVerticalIC;
    
    if (!isIC) {
      // For manager nodes, keep existing horizontal path logic
      const horizontalY = sourceY + VERTICAL_MARGIN / 2;
      const goingRight = targetX > sourceX;
      const curveDirection = goingRight ? 1 : -1;
      
      return `
        M ${sourceX} ${sourceY}
        L ${sourceX} ${horizontalY - EDGE_RADIUS}
        Q ${sourceX} ${horizontalY} ${sourceX + (EDGE_RADIUS * curveDirection)} ${horizontalY}
        L ${targetX - (EDGE_RADIUS * curveDirection)} ${horizontalY}
        Q ${targetX} ${horizontalY} ${targetX} ${horizontalY + EDGE_RADIUS}
        L ${targetX} ${targetY}
      `;
    }

    // Modified IC path logic
    const siblingEdges = edges.filter(e => e.data?.isVerticalIC);
    const isFirstIC = siblingEdges[0]?.id === id;
    
    // Calculate the shared vertical line position
    const verticalLineX = sourceX - IC_OFFSET;
    const verticalStart = sourceY;
    const verticalMiddle = verticalStart + VERTICAL_MARGIN * 2;

    // For the first IC edge, draw the main vertical line
    if (isFirstIC) {
      return `
        M ${sourceX} ${verticalStart}
        L ${verticalLineX} ${verticalStart}
        L ${verticalLineX} ${verticalMiddle}
        L ${targetX} ${verticalMiddle}
        L ${targetX} ${targetY}
      `;
    }

    // For subsequent IC edges, connect to the existing vertical line
    return `
      M ${verticalLineX} ${verticalMiddle}
      L ${targetX} ${verticalMiddle}
      L ${targetX} ${targetY}
    `;
  };

  return (
    <path
      id={id}
      style={{
        ...style,
        strokeWidth: 2,
        stroke: '#555',
        fill: 'none',
      }}
      className="react-flow__edge-path"
      d={getHierarchicalPath()}
      markerEnd={markerEnd}
    />
  );
};

export default CustomEdge;
