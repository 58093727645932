import { Lock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface FooterProps {
  variant?: 'default' | 'login';
}

const roleColors: { [key: string]: string } = {
  'promoter': 'bg-blue-50 text-blue-600',
  'hr_admin': 'bg-purple-50 text-purple-600',
  'employee': 'bg-green-50 text-green-600',
  'admin': 'bg-amber-50 text-amber-600'
};

const roleLabels: { [key: string]: string } = {
  'promoter': 'Promoter',
  'hr_admin': 'HR Admin',
  'employee': 'Personal',
  'admin': 'Admin'
};

export function Footer({ variant = 'default' }: FooterProps) {
  const isLogin = variant === 'login';
  const { user } = useAuth();
  
  return (
    <footer className={`
      flex items-center justify-between 
      bg-gradient-to-r from-[#FFF5D6] to-[#F2E2A8] 
      border-t border-gray-200
      px-4 md:px-8
      ${isLogin ? 'py-6' : 'py-3'}
    `}>
      <div className={`
        flex items-center gap-2 
        text-gray-600
        ${isLogin ? 'text-base' : 'text-sm'}
      `}>
        <Lock className={`${isLogin ? 'h-5 w-5' : 'h-4 w-4'} text-primary`} />
        <span>
          End-to-end encrypted. Your data stays private and secure.
        </span>
      </div>
      
      <div className="flex items-center gap-3">
        {user && user.role && (
          <span className={`
            px-2 py-0.5 
            rounded-full 
            text-xs 
            font-medium 
            ${roleColors[user.role]}
            transition-colors
            duration-200
          `}>
            {roleLabels[user.role]}
          </span>
        )}
        <Link 
          to="/" 
          className="font-arial text-2xl font-black tracking-tighter text-black lowercase hover:opacity-80 transition-opacity"
        >
          frenem
        </Link>
      </div>
    </footer>
  );
} 