import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';

interface User {
  role: 'admin' | 'promoter' | 'employee' | 'hr_admin';
  employeeId: string | null;
  company: string;
  userId: string;
  hasAcknowledgedKras: boolean;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  requestOTP: (email: string) => Promise<boolean>;
  verifyOTP: (email: string, otp: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (updatedUser: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        if (
          parsedUser &&
          typeof parsedUser.role === 'string' &&
          typeof parsedUser.hasAcknowledgedKras === 'boolean'
        ) {
          setIsAuthenticated(true);
          setUser(parsedUser);
        }
      } catch (error) {
        console.error('Error parsing stored user:', error);
        localStorage.removeItem('user');
      }
    }
  }, []);

  const requestOTP = async (email: string): Promise<boolean> => {
    try {
      const response = await api.post('/api/accounts/request-otp/', { email });
      
      // If the response includes user data, it means it's a demo user and already logged in
      if (response.data.role) {
        const user: User = {
          role: response.data.role,
          employeeId: response.data.employeeId,
          company: response.data.company,
          userId: response.data.userId,
          hasAcknowledgedKras: response.data.hasAcknowledgedKras || false
        };
        setIsAuthenticated(true);
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        return true; // Indicate this was a demo user
      }
      
      if (response.status !== 200) {
        throw new Error('Failed to send OTP');
      }
      return false; // Indicate this was not a demo user
    } catch (error) {
      console.error('OTP request error:', error);
      throw error;
    }
  };

  const verifyOTP = async (email: string, otp: string): Promise<void> => {
    try {
      const response = await api.post('/api/accounts/verify-otp/', { email, otp });

      if (response.status === 200) {
        const data = response.data;
        const user: User = {
          role: data.role,
          employeeId: data.employeeId,
          company: data.company,
          userId: data.userId,
          hasAcknowledgedKras: data.hasAcknowledgedKras || false
        };
        setIsAuthenticated(true);
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        throw new Error('OTP verification failed');
      }
    } catch (error) {
      console.error('OTP verification error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await api.post('/api/accounts/logout/');
      if (response.status === 200) {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('user');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const updateUser = (updatedUser: User) => {
    if (
      !updatedUser ||
      typeof updatedUser.hasAcknowledgedKras !== 'boolean' ||
      typeof updatedUser.role !== 'string'
    ) {
      console.error('Invalid user data provided to updateUser');
      return;
    }

    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
    
    if (updatedUser.hasAcknowledgedKras) {
      api.post('/api/accounts/acknowledge-kras/', {
        userId: updatedUser.userId
      }).catch(error => {
        console.error('Error syncing user acknowledgment with backend:', error);
      });
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, requestOTP, verifyOTP, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
