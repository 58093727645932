import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import Approval from './components/Approval';
import OrgChart from './components/OrgChart';
import Login from './components/Login';
import Goals from './components/Goals';
import Decisions from './components/decisions/Decisions';
import { KPIProvider } from './contexts/KPIContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import MoonshotsCollect from './components/moonshots/MoonshotsCollect';
import MoonshotsDisplay from './components/moonshots/MoonshotsDisplay';
import ProtectedRoute from './components/ProtectedRoute';
import Unauthorized from './components/Unauthorized';
import EmployeeDashboard from './components/Employee';
import EditHistory from './components/EditHistory';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/globals.css';
import { Footer } from './components/ui/footer';
import FrenemLandingPage from './components/Welcome';
import FeedbackForm from './components/FeedbackForm';

const Header: React.FC = () => {
  const { isAuthenticated, logout, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      if (user && user.company) {
        try {
          const logoModule = await import(`./assets/${user.company} Logo.png`);
          setLogo(logoModule.default);
        } catch (error) {
          console.error(`Failed to load logo for ${user.company}:`, error);
        }
      }
    };

    loadLogo();
  }, [user]);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  if (!isAuthenticated) return null;

  const isActive = (path: string) => {
    if (path === '/moonshots') {
      return location.pathname === '/moonshots/display';
    }
    return location.pathname.startsWith(path);
  };

  const navLinkClass = (path: string) =>
    `text-sm sm:text-base font-semibold px-3 sm:px-6 py-2 sm:py-3 rounded-lg transition-all duration-200 whitespace-nowrap ${
      isActive(path)
        ? 'text-white bg-black shadow-md transform scale-105'
        : 'text-black hover:bg-black hover:text-white hover:scale-105'
    }`;

  const renderNavLinks = () => {
    const links = [];

    if (user?.role === 'admin' || user?.role === 'promoter' || user?.role === 'hr_admin') {
      links.push(
        <Link 
          key="orgchart" 
          to="/orgchart" 
          className={navLinkClass('/orgchart')}
        >
          Your Organisation
        </Link>
      );
      links.push(
        <Link 
          key="decisions" 
          to="/decisions" 
          className={navLinkClass('/decisions')}
        >
          Decisions
        </Link>
      );
      
      if (user?.role !== 'hr_admin') {
        links.push(
          <Link 
            key="innovations" 
            to="/moonshots/display" 
            className={navLinkClass('/moonshots')}
          >
            Innovations
          </Link>
        );
      }
      
      links.push(
        <Link 
          key="goals" 
          to="/goals" 
          className={navLinkClass('/goals')}
        >
          Goals
        </Link>
      );
    }

    if (user?.role === 'employee') {
      links.push(
        <Link 
          key="dashboard" 
          to="/employee-dashboard" 
          className={navLinkClass('/employee-dashboard')}
        >
          Dashboard
        </Link>
      );
      links.push(
        <Link 
          key="orgchart" 
          to="/employee-orgchart" 
          className={navLinkClass('/employee-orgchart')}
        >
          Your Organisation
        </Link>
      );
      links.push(
        <Link 
          key="goals" 
          to="/goals" 
          className={navLinkClass('/goals')}
        >
          Goals
        </Link>
      );
    }

    if (user?.role === 'employee' || user?.role === 'admin') {
      links.push(
        <Link 
          key="moonshots-collect" 
          to="/moonshots/collect" 
          className={navLinkClass('/moonshots/collect')}
        >
          Innovations
        </Link>
      );
    }

    if (user?.role === 'admin') {
      links.push(
        <Link 
          key="approval" 
          to="/approval" 
          className={navLinkClass('/approval')}
        >
          Approvals
        </Link>
      );
    }

    return links;
  };

  return (
    <header className="bg-background border-b border-black text-black h-auto sm:h-16 py-2 sm:pt-1.5 app-header shadow-md">
      <div className="px-3 sm:px-6 flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0">
        <div className="flex items-center space-x-4 w-full sm:w-auto justify-between">
          {logo ? (
            <img 
              src={logo} 
              alt={`${user?.company} Logo`} 
              className="h-6 sm:h-8 w-auto cursor-pointer" 
            />
          ) : (
            <span className="font-arial text-2xl font-black tracking-tighter text-black lowercase">
              frenem
            </span>
          )}
          <button
            onClick={handleLogout}
            className="sm:hidden inline-flex items-center px-2 py-1 text-sm font-medium rounded-lg text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200 shadow-sm"
          >
            <LogOut size={16} className="mr-1" />
            <span className="text-xs">Logout</span>
          </button>
        </div>
        
        <nav className="w-full sm:flex-grow flex justify-start sm:justify-center items-center overflow-x-auto">
          <div className="flex space-x-2 sm:space-x-6 px-2 sm:px-0">
            {renderNavLinks()}
          </div>
        </nav>
        
        <button
          onClick={handleLogout}
          className="hidden sm:inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-lg text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200 shadow-sm"
        >
          <LogOut size={16} className="mr-2" />
          Logout
        </button>
      </div>
    </header>
  );
};

const AppRoutes: React.FC = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Routes>
      <Route path="/" element={
        isAuthenticated 
          ? (user?.role === 'employee' 
              ? <Navigate to="/employee-dashboard" replace /> 
              : <Navigate to="/orgchart" replace />)
          : <div className="flex-1 overflow-auto">
              <FrenemLandingPage />
            </div>
      } />
      <Route path="/login" element={<Login />} />
      <Route path="/approval" element={<ProtectedRoute element={<Approval />} allowedRoles={['admin']} />} />
      <Route path="/decisions" element={<ProtectedRoute element={<Decisions />} allowedRoles={['admin', 'promoter', 'hr_admin']} />} />
      <Route path="/moonshots/collect" element={<ProtectedRoute element={<MoonshotsCollect />} allowedRoles={['employee', 'admin']} />} />
      <Route path="/moonshots/display" element={<ProtectedRoute element={<MoonshotsDisplay />} allowedRoles={['admin', 'promoter']} />} />
      <Route path="/unauthorized" element={
        <div className="flex-1 overflow-auto">
          <Unauthorized />
        </div>
      } />
      <Route path="/employee-dashboard" element={<ProtectedRoute element={<EmployeeDashboard />} allowedRoles={['employee']} />} />
      <Route path="/employee-orgchart" element={<ProtectedRoute element={<OrgChart isEmployeeView={true} />} allowedRoles={['employee']} />} />
      <Route path="/orgchart" element={<ProtectedRoute element={<OrgChart />} allowedRoles={['admin', 'promoter', 'hr_admin']} />} />
      <Route 
        path="/goals" 
        element={
          <ProtectedRoute 
            element={
              <Goals 
                isEmployeeView={user?.role === 'employee'} 
                employeeId={user?.employeeId}
              />
            } 
            allowedRoles={['admin', 'promoter', 'hr_admin', 'employee']} 
          />
        } 
      />
      <Route 
        path="/edit-history" 
        element={
          <ProtectedRoute 
            element={<EditHistory />} 
            allowedRoles={['admin', 'promoter']} 
          />
        } 
      />
      <Route path="/feedback/:token" element={<FeedbackForm />} />
      <Route 
        path="/feedback/success" 
        element={
          <div className="flex-1 overflow-auto">
            <div className="max-w-md mx-auto mt-8 p-4 sm:p-6 bg-white rounded-lg shadow-md text-center">
              <h2 className="text-xl sm:text-2xl font-bold mb-4">Thank You!</h2>
              <p>Your feedback has been successfully submitted.</p>
            </div>
          </div>
        } 
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const isLargeFooter = location.pathname === '/login' || location.pathname === '/';

  return (
    <AuthProvider>
      <KPIProvider>
        <div className="min-h-screen flex flex-col">
          <Header />
          <main className="flex-1 flex flex-col overflow-hidden">
            <div className="flex-1 flex flex-col overflow-hidden">
              <AppRoutes />
            </div>
          </main>
          <Footer variant={isLargeFooter ? "login" : "default"} />
        </div>
      </KPIProvider>
    </AuthProvider>
  );
};

export default App;
