import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import api from '../api';
import { useAuth } from './AuthContext';

const LOCAL_STORAGE_KEY = 'kpi_departments_data';

export interface Responsibility {
  id: number;
  description: string;
}

export interface KRA {
  id: number;
  description: string;
  needs_review: boolean;
  responsibilities: Responsibility[];
}

export interface KPI {
  id: number;
  description: string;
  needs_review: boolean;
}

export interface Employee {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string | null;
  is_active: boolean;
  competencies: any[];
}

export interface Goal {
  id: number;
  title: string;
  description: string;
  progress: number;
  status: string;
  due_date: string;
  timeline?: string;
}

export interface Decision {
  id: number;
  description: string;
  date: string;
  status: string;
}

export interface Role {
  id: number;
  title: string;
  department: string;
  location: string;
  grade: string;
  current_employee: Employee | null;
  reporting_to: {
    id: number;
    title: string;
    current_employee: Employee | null;
  } | null;
  secondary_reporting_to: {
    id: number;
    title: string;
    current_employee: Employee | null;
  } | null;
  kras: KRA[];
  kpis: KPI[];
  goals: Goal[];
  decisions: Decision[];
}

export interface Department {
  name: string;
  roles: Role[];
}

export interface KPIContextType {
  departments: Department[];
  approvalPending: boolean;
  updateDepartments: (newDepartments: Department[]) => void;
}

const KPIContext = createContext<KPIContextType | undefined>(undefined);

export const KPIProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [approvalPending, setApprovalPending] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const fetchKPIData = useCallback(async () => {
    if (!user?.company) {
      setLoading(false);
      return;
    }

    try {
      const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (cachedData) {
        const { departments: cachedDepartments, timestamp, company } = JSON.parse(cachedData);
        if (Date.now() - timestamp < 5 * 60 * 1000 && company === user.company) {
          setDepartments(cachedDepartments);
          return;
        }
      }

      const response = await api.get(`/api/company/${user.company}/`);
      const { departments: transformedData, approval_pending } = response.data;
      
      setDepartments(transformedData);
      setApprovalPending(approval_pending);
      
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        departments: transformedData,
        timestamp: Date.now(),
        company: user.company
      }));
    } catch (err: any) {
      console.error('Error fetching KPI data:', err);
      setError('Failed to load KPI data.');
      
      const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (cachedData) {
        const { departments: cachedDepartments, company } = JSON.parse(cachedData);
        if (company === user.company) {
          setDepartments(cachedDepartments);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [user?.company]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      await fetchKPIData();
    };
    
    fetchData();
    
    let intervalId: NodeJS.Timeout | undefined;
    if (user?.company) {
      intervalId = setInterval(fetchData, 60000);
    }
    
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchKPIData]);

  const updateDepartments = (newDepartments: Department[]) => {
    setDepartments(newDepartments);
    
    if (user?.company) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        departments: newDepartments,
        timestamp: Date.now(),
        company: user.company
      }));
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <KPIContext.Provider value={{ departments, approvalPending, updateDepartments }}>
      {children}
    </KPIContext.Provider>
  );
};

export const useKPI = () => {
  const context = useContext(KPIContext);
  if (context === undefined) {
    throw new Error('useKPI must be used within a KPIProvider');
  }
  return context;
};
